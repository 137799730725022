import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
	secondaryBar: {
		zIndex: 0,
	},
})

function TabBar({ classes, tab, onTabChange, tabNames = [] }) {
	const [value, setValue] = React.useState(Math.max(tabNames.indexOf(tab), 0))
	const handleChange = (event, newValue) => {
		setValue(newValue)
		if (onTabChange) {
			onTabChange(newValue, tabNames[newValue])
		}
	}
	useEffect(() => {
		const currentTab = Math.max(tabNames.indexOf(tab), 0)
		if (currentTab !== value) {
			handleChange({}, currentTab)
		}
	}, [tab])

	return (
		<AppBar component="div" className={classes.secondaryBar} color="primary" position="static" elevation={0}>
			<Tabs value={value} onChange={handleChange} textColor="inherit" variant="scrollable" scrollButtons="auto">
				{tabNames.map((tabName, index) => (
					<Tab key={index} textColor="inherit" label={`${tabName}`} />
				))}
			</Tabs>
		</AppBar>
	)
}

TabBar.propTypes = {
	classes: PropTypes.object.isRequired,
	tabNames: PropTypes.array.isRequired,
	onTabChange: PropTypes.func,
	tab: PropTypes.string,
}

export default withStyles(styles)(TabBar)
