import React from 'react'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'

import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import RefreshIcon from '@material-ui/icons/Refresh'
import IconButton from '@material-ui/core/IconButton'

import ListContent from 'pages/ListContent'
import PrivateRoute from 'utils/PrivateRoute'
import { withTranslation } from 'react-i18next'
import { LinearProgress } from '@components'

const lightColor = 'rgba(255, 255, 255, 0.7)'

const styles = (theme) => ({
	secondaryBar: {
		zIndex: 0,
	},
	button: {
		borderColor: lightColor,
	},
})

function List({ t, classes, location, pageContext, setLabelingInstructionsVisible, list }) {
	const title = t(`${pageContext.titleKey}.title`)
	return (
		<>
			<AppBar component="div" className={classes.secondaryBar} color="primary" position="static" elevation={0}>
				<Toolbar>
					<Grid container alignItems="center" spacing={2}>
						<Grid item xs>
							<Typography style={{ textTransform: 'capitalize' }} color="inherit" variant="h5">
								{title}
							</Typography>
						</Grid>
						<Grid item>
							<Button
								className={classes.button}
								variant="outlined"
								color="inherit"
								size="small"
								onClick={() => {
									window.open("https://dieta-public-resources.s3.us-west-2.amazonaws.com/Videos/CS1710-Labeling-Instructions.mp4");
								}}
							>
								Instructions
							</Button>
						</Grid>
					</Grid>
					<Grid item xs />
				</Toolbar>
			</AppBar>
			<ListContent />
		</>
	)
}

List.propTypes = {
	location: PropTypes.object,
	list: PropTypes.shape({
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	setLabelingInstructionsVisible: PropTypes.func.isRequired,
}

const ListPage = ({ location, ...rest }) => {
	return <PrivateRoute location={location} component={List} {...rest} />
}

const mapStateToProps = ({ list }) => {
	return {
		list,
	}
}
export default compose(connect(mapStateToProps, actions), withStyles(styles), withTranslation())(ListPage)
