import React from 'react'
import { navigate } from 'gatsby'
import { configuredStore } from 'src/configureStore'

import { paths } from 'routes'

const PrivateRoute = ({ component: Component, location, ...rest }) => {
	const { auth } = configuredStore.store.getState()
	if (!auth.accessToken) {
		navigate(paths.login)
		return <></>
	}
	return <Component location={location} {...rest} />
}
export default PrivateRoute
