// mean absolute error measurement should be used to measure the accuracy of this model
/* 
Make sure they understand the problem
Have them use my code, and verify that they can get it running
  - give an out if this is shakey
  - try out the autoML or built in sagemaker models 
Fine tuning
*/

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Content, EntitySummaryList } from '@components'
import Alert from '@material-ui/core/Alert'
import Typography from '@material-ui/core/Typography'

import { paths } from 'routes'
import { navigate } from 'gatsby'
import { useQueryParams, StringParam } from 'use-query-params'
import { useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import moment from 'moment'
import { LabelingDialog, ListCharacteristicsChiplist } from 'molecules'
import Backdrop from '@material-ui/core/Backdrop'
import Button from '@material-ui/core/Button'
import Fade from '@material-ui/core/Fade'
import Modal from '@material-ui/core/Modal'
import { AsyncParser } from 'json2csv'

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
	},
	roleContainer: {
		paddingTop: theme.spacing(4),
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: 100,
	},
	containImage: {
		width: '100%',
	},
}))

const renderAlertWarnings = (interestedCharacteristics) => {
	if (interestedCharacteristics.length === 0) {
		return (
			<Alert variant="outlined" severity="error">
				Please select one classification to start labeling
			</Alert>
		)
	}
	if (interestedCharacteristics.length > 1) {
		return (
			<Alert variant="outlined" severity="warning">
				Only select one classification at a time!
			</Alert>
		)
	}
	return (
		<Alert variant="outlined" severity="info">
			Select the characteristic you would like to label from the below list, then click Annotate next to an entry to open the labeling interface.
		</Alert>
	)
}

const ListContent = ({
	location,
	auth,
	firestore,
	labeling,
	setTableWorklist,
	setTableVisibility,
	setLabelingId,
	setListDb,
	setLabelingInstructionsVisible,
	setRenderedTable,
	setLabelingImageZoomPreference,
}) => {
	const classes = useStyles()
	const [query, setQuery] = useQueryParams({
		sub: StringParam,
	})
	const [instructionsModalOpen, setInstructionsModalVisibility] = useState(false)

  if (!labeling.currentDatabase) {
    navigate('/')
  }
  useFirestoreConnect(labeling.currentDatabase)

	let data = useSelector((state) => state.firestore.ordered[labeling.currentDatabase])
	// const isAdmin = labeling.profile && labeling.profile.role === "Admin"
  const isAdmin = false
	return (
		<div className={classes.root}>
			{renderAlertWarnings(labeling.interestedCharacteristics)}
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<ListCharacteristicsChiplist />
					<EntitySummaryList
						admin={isAdmin}
            teamMemberId={labeling.teamMemberId}
            teamSize={labeling.teamSize}
            role={labeling?.profile?.role || "Labeler"}
            submissionModeActive={labeling.submissionModeActive}
						data={data}
						setLabelingId={setLabelingId}
						setTableVisibility={setTableVisibility}
						setTableWorklist={setTableWorklist}
						isOpenLabeler={labeling.open}
						requiredWork={labeling.characteristics.map((char) => char.name)}
						labelerOpen={labeling.open}
						currentDatabase={labeling.currentDatabase}
						setRenderedTable={setRenderedTable}
						interestedCharacteristics={labeling.interestedCharacteristics}
						setLabelingImageZoomPreference={setLabelingImageZoomPreference}
					/>
					<LabelingDialog store={labeling.currentDatabase} />
				</Grid>
			</Grid>
			<Grid container spacing={4}>
				<Grid item xs></Grid>
				<Grid item>
					<Button
						color="primary"
						variant="contained"
						style={{ textTransform: 'none' }}
						size="small"
						onClick={async () => {
							const headers = {}
							const dataset = data.reduce((acc, item) => {
								const labels = Object.keys(item.labels).filter((label) => !label.startsWith('_'))
								let row = {
									uuid: item.id,
									...labels.reduce((acc, label) => {
										if (!headers[label]) {
											headers[label] = true
										}
										acc[label] = item.labels[label]
										return acc
									}, {}),
								}
								if (isAdmin) {
									if (!headers['occurred_at']) {
										headers['occurred_at'] = true
									}
									if (item.meta.confidence) {
										const confidences = Object.keys(item.meta.confidence)
										row = {
											...row,
											occurred_at: item.meta.occurred_at,
											...confidences.reduce((acc, confidence) => {
												if (!headers[`${confidence}_confidence`]) {
													headers[`${confidence}_confidence`] = true
												}
												if (!headers[`${confidence}_manual_intervention`]) {
													headers[`${confidence}_manual_intervention`] = true
												}
												acc[`${confidence}_confidence`] = item.meta.confidence[confidence]
												if (item.meta[`${confidence}_labeled_at`]) {
													acc[`${confidence}_manual_intervention`] = true
												}
												return acc
											}, {}),
										}
									}
								}
								acc.push(row)
								return acc
							}, [])

							const asyncParser = new AsyncParser({
								fields: ['uuid', ...Object.keys(headers).sort()].map((key) => ({
									label: key,
									value: key,
								})),
							})
							const promise = asyncParser.promise()
							const stringifiedrecords = dataset.map((record) => JSON.stringify(record))
							stringifiedrecords.forEach((record) => asyncParser.input.push(record))
							asyncParser.input.push(null) // end the stream
							const csv = await promise
							const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
							if (navigator.msSaveBlob) {
								// IE 10+
								navigator.msSaveBlob(blob, 'export.csv')
							} else {
								const link = document.createElement('a')
								if (link.download !== undefined) {
									// feature detection
									// Browsers that support HTML5 download attribute
									const url = URL.createObjectURL(blob)
									link.setAttribute('href', url)
									link.setAttribute('download', 'export.csv')
									link.style.visibility = 'hidden'
									document.body.appendChild(link)
									link.click()
									document.body.removeChild(link)
								}
							}

							// let csv = ''
							// parser
							// 	.parse(data)
							// 	.on('data', (chunk) => (csv += chunk.toString()))
							// 	.on('end', () => console.log(csv))
							// 	.on('error', (err) => console.error(err))
							// 	// You can also listen for events on the conversion and see how the header or the lines are coming out.
							// 	.on('header', (header) => console.log(header))
							// 	.on('line', (line) => console.log(line))
						}}
					>
						Export
					</Button>
				</Grid>
			</Grid>
			<Modal
				className={classes.modal}
				open={labeling.instructionsModalOpen}
				onClose={() => {
					setLabelingInstructionsVisible(false)
				}}
				aria-labelledby="labeling instructions"
				aria-describedby="view instructions"
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={labeling.instructionsModalOpen}>
					<Grid container spacing={4}>
						<Grid item xs={12}>
							<img
								className={classes.containImage}
								src={
									'https://dieta-public-resources.s3-us-west-2.amazonaws.com/labeling_instructions.png'
								}
							></img>{' '}
						</Grid>
					</Grid>
				</Fade>
			</Modal>
		</div>
	)
}

ListContent.propTypes = {
	location: PropTypes.object,
	firestore: PropTypes.shape({
		status: PropTypes.shape({}),
	}),
	auth: PropTypes.shape({}),
	labeling: PropTypes.shape({
		characteristics: PropTypes.array,
		currentDatabase: PropTypes.string,
    profile: PropTypes.shape({
      role: PropTypes.string
    }),
    teamMemberId: PropTypes.number,
    teamSize: PropTypes.number,
	}),
	setLabelingId: PropTypes.func.isRequired,
	setTableWorklist: PropTypes.func.isRequired,
	setTableVisibility: PropTypes.func.isRequired,
	setInterestedCharacteristics: PropTypes.func.isRequired,
	setListDb: PropTypes.func.isRequired,
	setLabelingInstructionsVisible: PropTypes.func.isRequired,
	setRenderedTable: PropTypes.func.isRequired,
	setLabelingImageZoomPreference: PropTypes.func.isRequired,
}

const mapStateToProps = ({ auth, labeling, firestore }) => {
	return {
		auth,
		labeling,
		firestore,
	}
}

export default connect(mapStateToProps, actions)(ListContent)
