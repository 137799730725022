import React, { useCallback, useEffect, useState, useRef } from 'react'
import { makeStyles, lighten } from '@material-ui/core/styles'
import classNames from 'classnames'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'

import _debounce from 'lodash.debounce'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'flex-start',
		flexWrap: 'wrap',
		listStyle: 'none',
		padding: theme.spacing(0.5),
		margin: 0,
	},

	hideLastBorder: {
		'&:last-child td, &:last-child th': {
			border: 0,
		},
	},

	outline: {
		borderWidth: 1,
		borderStyle: 'solid',
	},
	highlight:
		theme.palette.mode === 'light'
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85),
			  }
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark,
			  },
}))

const LowConfidenceMarker = (props) => {
	const classes = useStyles()

	const characteristic = props.characteristic
	const uid = props.uid
	const [selected, setSelected] = useState(props.selected)

	const onToggle = () => {
		setSelected(selected => !selected)
		props.onToggle(uid, characteristic, selected)
	}

	useEffect(() => {
		setSelected(props.selected || false)
	}, [props.uid])

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} size="small" aria-label="label shortcuts">
				<TableBody>
					<TableRow
						className={classNames([classes.hideLastBorder, { [classes.highlight]: selected }])}
						tabIndex={-1}
						role="checkbox"
						aria-checked={selected}
						hover
						// key={i}
						selected={selected}
						onClick={onToggle}
					>
						<TableCell component="th" scope="row">
							Low Confidence
						</TableCell>
						<TableCell padding="checkbox">
							<Checkbox checked={selected} color="primary" onChange={(event) => event.target.blur()} />
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export { LowConfidenceMarker }
