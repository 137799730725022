import React, { Component, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'

const hue2rgb = (p, q, t) => {
  if(t < 0) t += 1;
  if(t > 1) t -= 1;
  if(t < 1/6) return p + (q - p) * 6 * t;
  if(t < 1/2) return q;
  if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
  return p;
}

const hslToRgb = (h, s, l) => {
  var r, g, b;

  if (s == 0){
    r = g = b = l; // achromatic
  } else{
    var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    var p = 2 * l - q;
    r = hue2rgb(p, q, h + 1/3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1/3);
  }

  return [Math.floor(r * 255), Math.floor(g * 255), Math.floor(b * 255)];
}


const useStyles = makeStyles((theme) => ({
	
}))

const HeatmapLabelsComponent = (props) => {
  const classes = useStyles()
  const [document, setDocument] = useState(props.document)
  useEffect(() => {
    setDocument(props.document)
  }, [props.document.id, props.document.meta])
  const maxRows = Object.keys(document.history).length;
  const rowsNames =  Object.keys(document.history).map((row) => {
    const dbname = row.split('-').pop()
    return dbname;
  })
  const labels = Object.keys(document.history).reduce((acc, db) => {
    const dbname = db.split('-').pop()
    Object.keys(document.history[db]).forEach((label) => {
      if (!acc[label]) {
        acc[label] = rowsNames.map((r)=>{
          return {
            source: r,
            label: label,
            user: '',
            value: '',
          }
        })
      }

      acc[label] = acc[label].map((it) => {
        if (it.source === dbname) {
          it = {
            ...it,
            user: document.history[db][label].user,
            value: `${document.history[db][label].value}`,
          }
        }
        return it;
      })
    })
    
    return acc;
  }, {})
  

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" >
        <TableHead>
          <TableRow>
            <TableCell>Label</TableCell>
            {rowsNames.map((db, i) => {
              return (<TableCell key={i}>{db}</TableCell>)
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(labels).map((rowKey, i) => {
            const row = labels[rowKey]
            // console.log(row);
            // console.log(hslToRgb(hue, 1, .5));
            return (
              <TableRow
                className={classNames([classes.hideLastBorder, { [classes.highlight]: row.selected }])}
                tabIndex={-1}
                key={i}
              >
                <TableCell component="th" scope="row">
                  {rowKey}
                </TableCell>
                {row.map((labelChoice, i) => {
                  return (<Tooltip title={labelChoice.user}><TableCell key={i}>{labelChoice.value}</TableCell></Tooltip>)
                })}
                {/* <TableCell align="right" scope="row">
                  {row.value}
                </TableCell> */}
                {/* <TableCell align="right">
                  <Typography variant="subtitle1">{row.user}</Typography>
                </TableCell> */}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

HeatmapLabelsComponent.propTypes = {
  auth: PropTypes.shape({
    email: PropTypes.string.isRequired
  }),
	labeling: PropTypes.shape({
		uid: PropTypes.string.isRequired,
    currentDatabase: PropTypes.string,
    profile: PropTypes.shape({
      role: PropTypes.string
    }),
	}),
  document: PropTypes.shape({})
}

const mapStateToProps = ({ auth, labeling, firestore }) => {
	return {
    auth,
		labeling,
		firestore,
	}
}

const HeatmapLabels = connect(mapStateToProps, actions)(HeatmapLabelsComponent)
export default HeatmapLabels