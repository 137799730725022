import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Grid from '@material-ui/core/Grid'

import { useTheme, withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
	faDumbbell,
	faPills,
	faQuestionCircle,
	faUtensils,
	faNotesMedical,
	faSmile,
	faPoo,
	faBed,
} from '@fortawesome/free-solid-svg-icons'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import VisibilityIcon from '@material-ui/icons/Visibility'
import BurstModeIcon from '@material-ui/icons/BurstMode'
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn'
import Divider from '@material-ui/core/Divider'
import LockOpen from '@material-ui/icons/LockOpen'
import Lock from '@material-ui/icons/Lock'
import Input from '@material-ui/core/Input'

import { ENTITY_THEME } from 'constants'

const getIcon = (type, index, list, handleClick, handleDelete) => {
	const isEnabled = list.includes(type)
	const deleteIcon = isEnabled ? (
		<VisibilityIcon style={{ color: '#303030' }} />
	) : (
		<VisibilityOffIcon style={{ color: '#303030' }} />
	)

	const iconStyles = {
		padding: 2,
		color: '#303030',
		fontSize: 18,
	}

	const chipStyles = {
		color: '#303030',
		opacity: isEnabled ? 1 : 0.2,
	}

	const onClick = () => (isEnabled ? handleDelete() : handleClick())
	const onDelete = () => (isEnabled ? handleDelete() : handleClick())
	return (
		<Chip
			key={type}
			size="small"
			icon={<HelpOutlineIcon style={iconStyles} />}
			style={{
				...chipStyles,
				backgroundColor: ENTITY_THEME.SLEEP,
			}}
			label={type}
			deleteIcon={deleteIcon}
			onClick={onClick}
			onDelete={onDelete}
		/>
	)
}

const styles = (theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5),
		},
		marginTop: 20,
		marginBottom: 20,
	},
  pillGroup: {
    display: 'flex',
    justifyContent: 'center',
  },
  input: {
    width: 20,
    height: 25,
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  labelValue: {
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
  },
})

const onClick = (currentSet, type, cb) => {
	const set = new Set(currentSet)
	set.add(type)
	// cb(Array.from(set))
  cb([type])
}

const onDelete = (currentSet, type, cb) => {
	const set = new Set(currentSet)
	set.delete(type)
	// cb(Array.from(set))
  cb([type])
}

function ListCharacteristicsChiplistComponent(props) {
	const { classes } = props
  const theme = useTheme();
	return (
		<div className={classes.root}>
      {!props.auth.email.endsWith("@cshs.org") ? (
        <>
          {/* <div className={classes.pillGroup}>
            <Chip
            className={classes.chip}
              size={"small"}
              deleteIcon={<AssignmentTurnedIn style={{ color: '#303030' }} />}
              label={"Team Size"}
              onDelete={() => {}}
              style={{}}
            />
            
            <Input
              type={'number'}
              className={classes.input}
              value={props.labeling.teamSize}
              inputProps={{ 'aria-label': 'team size (1, 2, 3, ...)' }}
              onChange={(val) => {
                if (val.target.value !== null) {
                  props.setTeamSize(parseInt(val.target.value))
                }
              }}
            />
          </div> */}


          <div className={classes.pillGroup}>
            {/* <Chip
              className={classes.chip}
              size={"small"}
              deleteIcon={<AssignmentTurnedIn style={{ color: '#303030' }} />}
              label={"Team Member ID"}
              onDelete={() => {}}
              style={{}}
            /> */}
            
            {/* <Input
              type={'number'}
              className={classes.input}
              value={props.labeling.teamMemberId}
              inputProps={{ 'aria-label': 'member id (1, 2, 3, ...)' }}
              onChange={(val) => {
                if (val.target.value !== null) {
                  props.setTeamMemberId(parseInt(val.target.value))
                }
              }}
            /> */}
          </div>
          {/* <Divider orientation="vertical" flexItem /> */}
        </>
      ) : (<></>)}
    
			{props.labeling.characteristics.map((obj, index) => {
				return getIcon(
					obj.name,
					index,
					props.labeling.interestedCharacteristics,
					() =>
						onClick(props.labeling.interestedCharacteristics, obj.name, props.setInterestedCharacteristics),
					() =>
						onDelete(props.labeling.interestedCharacteristics, obj.name, props.setInterestedCharacteristics)
				)
			})}

      {props.labeling?.profile?.role === "Labeler" ? (<></>) : (
        <>
          <Divider orientation="vertical" flexItem />
          
          {
          /* AL 10/2022 - hiding these as there doesn't seem to be a purpose 
           <Chip
            size="small"
            style={{}}
            deleteIcon={<VisibilityIcon style={{ color: '#303030' }} />}
            label={'Show All'}
            onClick={() => props.setInterestedCharacteristics(props.labeling.characteristics.map((i) => i.name))}
            onDelete={() => {}}
          />
          <Chip
            size="small"
            style={{}}
            deleteIcon={<VisibilityOffIcon style={{ color: '#303030' }} />}
            label={'Hide All'}
            onClick={() => props.setInterestedCharacteristics([])}
            onDelete={() => {}}
          /> */}
        </>
      )}

      {/* {!props.auth.email.endsWith("@cshs.org") ? (
        <>
        <Divider orientation="vertical" flexItem />
        <Chip
          size="small"
          style={props.labeling.submissionModeActive ? {
            backgroundColor: theme.palette.primary[500]
          } : {}}
          deleteIcon={ props.labeling.submissionModeActive ? (<LockOpen style={{ color: '#303030' }} />) : (<AssignmentTurnedIn style={{ color: '#303030' }} />)}
          label={props.labeling.submissionModeActive ? 'Submit Mode' : "Annotate Mode"}
          onClick={() => props.toggleSubmissionLock()}
          onDelete={() => {}}
        />
        </>
      ) : (<></>)} */}
		
		</div>
	)
}

ListCharacteristicsChiplistComponent.propTypes = {
  auth: PropTypes.shape({
    email: PropTypes.string
  }),
	labeling: PropTypes.shape({
		characteristics: PropTypes.array,
		interestedCharacteristics: PropTypes.array,
    submissionModeActive: PropTypes.bool,
    profile: PropTypes.shape({
      role: PropTypes.string
    }),
    teamMemberId: PropTypes.number,
    teamSize: PropTypes.number,
	}),
	setInterestedCharacteristics: PropTypes.func.isRequired,
  toggleSubmissionLock: PropTypes.func.isRequired,
  setTeamSize: PropTypes.func.isRequired,
  setTeamMemberId: PropTypes.func.isRequired
}

const mapStateToProps = ({ auth, labeling }) => {
	return {
    auth,
		labeling,
	}
}

const ListCharacteristicsChiplist = compose(
	connect(mapStateToProps, actions),
	withStyles(styles),
	withTranslation()
)(ListCharacteristicsChiplistComponent)
export { ListCharacteristicsChiplist }
