import { useForm, Controller } from "react-hook-form";
import React, { useEffect } from "react";
import { TextField } from "@material-ui/core";
import {Button} from "@material-ui/core";

const BMComment = (props) => {
  const { control, handleSubmit, formState: { isDirty }, reset } = useForm({
    defaultValues: {
      comment: props.data,
    }
  });
  const onSubmit = data => props.onSave(props.uid, data.comment, reset);

  useEffect(() => {
    reset({comment: props.data})
  }, [props.uid])
  
  return (
		<form onSubmit={handleSubmit(onSubmit)} style={{ marginBottom: 20 }}>
			<div>
				<Controller
					name="comment"
					control={control}
					render={({ field }) => (
						<TextField
              id="outline-multiline-flexible"
              label="Enter Bowel Movement Comment"
              multiline
              {...field}
              style={{ width: '100%', marginBottom: 8 }}
						/>
					)}
				/>
			</div>
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					{isDirty && (
						<p style={{ color: '#f87171', fontSize: '.8rem', lineHeight: 1, margin: 0, padding: 0 }}>
							Unsaved changes
						</p>
					)}
				</div>
				<div style={{ marginLeft: 'auto' }}>
					<Button variant="contained" disabled={!isDirty}>
						Save Comment
						<input hidden type="Submit" />
					</Button>
				</div>
			</div>
		</form>
  )
};

export { BMComment }