import React, { Component, useCallback, useRef, useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as actions from 'actions'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { LabelChipList, LabelChipListEditable, LabelEfficientPrompt, LabelUniformityMarker } from '@components'
import Slider from '@material-ui/core/Slider'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import ArtTrackIcon from '@material-ui/icons/ArtTrack'
import Alert from '@material-ui/core/Alert'
import Switch from '@material-ui/core/Switch'
import Divider from '@material-ui/core/Divider'

import CircularProgress from '@material-ui/core/CircularProgress'

import ArrowBack from '@material-ui/icons/ArrowBack'
import ArrowForward from '@material-ui/icons/ArrowForward'
import RotateLeft from '@material-ui/icons/RotateLeft'
import { useFirestore } from 'react-redux-firebase'
import { Shaders, Node, GLSL } from 'gl-react'
import { Surface } from 'gl-react-dom'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import Sticky from 'react-stickynode'
import Drawer from '@material-ui/core/Drawer'
import moment from 'moment'
import _debounce from 'lodash.debounce'

import PermMedia from '@material-ui/icons/PermMedia'
import { useHotkeys } from 'react-hotkeys-hook'
import HeatmapLabels from './HeatmapLabels'
import { LowConfidenceMarker } from '../@components/LowConfidenceMarker'
// import { AdequateMarker } from '../@components/AdequateMarker'

import { BMComment } from '../@components/BMComment'

const images = [];

const VerboseLabelsNameMap = {
//   "bristol": "bristol",
//   "consistency": "consistency",
//   "volume": "volume",
//   "fragmentation": "fragmentation",
//   "edge_fuzziness": "fuzziness",
//   "buoyancy": "buoyancy",
//   "diameter": "diameter",
  "blood_amount": "blood_amount",
  "blood_color": "blood_color",
  "blood_inorout": "blood_inorout",
  "mucus_amount": "mucus_amount",
  "stool_inorout": "stool_inorout",
	"colo_prep": "colo_prep",
}

const Cache = {}
const drawerWidth = 360
const shaders = Shaders.create({
	Saturate: {
		frag: GLSL`
precision highp float;
varying vec2 uv;
uniform sampler2D t;
uniform float contrast, saturation, brightness;
const vec3 L = vec3(0.2125, 0.7154, 0.0721);
void main() {
  vec4 c = texture2D(t, uv);
  vec3 brt = c.rgb * brightness;
  gl_FragColor = vec4(mix(
    vec3(0.5),
    mix(vec3(dot(brt, L)), brt, saturation),
    contrast), c.a);
}
`,
	},
})

let refTimer = 0

export const Saturate = ({ contrast, saturation, brightness, children }) => (
	<Node shader={shaders.Saturate} uniforms={{ contrast, saturation, brightness, t: children }} />
)

class Field extends Component {
	render() {
		const { label, onChange, value, hotkey } = this.props
		return (
			<>
				<Typography>
					{hotkey ? (
						<Tooltip title={hotkey}>
							<span>{label}</span>
						</Tooltip>
					) : (
						label
					)}
				</Typography>
				<Slider
					value={value}
					marks={[
						{
							value: 1,
						},
					]}
					min={0}
					max={4}
					step={0.01}
					onChange={(e) => onChange(parseFloat(e.target.value))}
					valueLabelDisplay="auto"
				/>
			</>
		)
	}
}

const useStyles = makeStyles((theme) => ({
	paper: {
		margin: 'auto',
		overflow: 'hidden',
		marginBottom: 30,
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
  containedModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 400,
  },
	container: {
		padding: '48px 36px',
	},
	capitalize: {
		textTransform: 'capitalize',
	},
	dialogContainer: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	scrollGuideContainer: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		overflowY: 'auto',
		left: 0,
		right: 0,
	},
	containImage: {
		width: '100%',
		objectFit: 'cover',
		overflowX: 'hidden',
		overflowY: 'auto',
		textAlign: 'center',
	},
	outlineButton: {
		borderWidth: 2,
		borderStyle: 'solid',
		borderColor: theme.palette.primary[500],
	},
}))

const LabelingDialogComponent = (props) => {
	const {
    auth,
		labeling,
		firestore,
		setLabelingId,
		fetchOriginalImage,
		fetchLabelingImage,
		setLabelingGuidePreference,
		setLabelingImageZoomPreference,
		...rest
	} = props
  // const isAdmin = labeling.profile && labeling.profile.role === "Admin"
  const isAdmin = false
	const [preloadEnabled, setPreloadEnabled] = useState(false);
  const [suggestedLabels, setSuggestedLabels] = useState()
  useEffect(async () => {
    const res = await fetch("https://dieta-public-resources.s3-us-west-2.amazonaws.com/verbose_labels.json")
    setSuggestedLabels(await res.json())
  }, [])

	const interestedLabels = labeling.characteristics
		.map((charObj) => charObj.name)
		.reduce((acc, item, i) => {
			if (labeling.interestedCharacteristics.includes(item)) {
				acc.push(labeling.characteristics[i])
			}
			return acc
		}, [])
	const classes = useStyles()
	const guideRef = useRef()
	const fireStore = useFirestore()
	const [img, setImg] = useState('')
	const [imgLoading, setImgLoading] = useState(false)
	const [imgDims, setImgDims] = useState({ width: 700, height: 800 })
	const [imgIndex, setImgIndex] = useState(
		interestedLabels.length === 1 && interestedLabels[0].name === 'volume' ? -1 : 0
	)

	const [imageSettings, setImageSettings] = useState({
		contrast: 1,
		saturation: 1,
		brightness: 1,
	})
	const [labels, setLabels] = useState({})
	const [lowConfidenceLabels, setLowConfidenceLabels] = useState([])
	const [timer, setTimer] = useState(0)
	const [guideModalOpen, setGuideModalVisibility] = useState(false)
  const [shortcutsModalOpen, setGuideShortcutsModalVisibility] = useState(false)  
	const totalDataset = (firestore?.ordered && firestore.ordered[labeling.currentDatabase]) || []
	const richDocument = useMemo(() => totalDataset.find((item) => item.id === labeling.uid), [totalDataset, labeling])
	const index = labeling.worklistOrder[labeling.uid]

	const nextTarget = useMemo(() => {
		return labeling?.worklist[index + 1]
	}, [labeling, index])

	const nextRichDocument = useMemo(() => {
		return totalDataset.find((item) => item.id === nextTarget?.id)
	}, [totalDataset, nextTarget])

	const interestedCharacteristics = props.labeling.characteristics
		.map((charObj) => charObj.name)
		.reduce((acc, item, i) => {
			if (
				props.labeling.interestedCharacteristics.includes(
					item
				)
			) {
				acc.push(props.labeling.characteristics[i])
			}
			return acc
		}, [])

	const onEnter = useCallback(
		_debounce(async () => {
			if (labeling.open) {
				try {
					const target = labeling.worklist[index + 1]
					if (!target) {
						handleClose()
					} else {
						setImg('')
						setLabelingId(target.id)
						initImageIndex()
						setImageSettings({
							contrast: 1,
							saturation: 1,
							brightness: 1,
						})
					}
				} catch (err) {
					console.log(err)
				}
			}
		}, 100),
		[index]
	)

	useHotkeys(
		'Enter',
		() => {
			onEnter()
		},
		[labeling.uid]
	)

	useHotkeys(
		'Esc',
		() => {
			if (labeling.open) {
				handleClose()
			}
		},
		[labeling.open]
	)

	useHotkeys(
		'o',
		() => {
			if (labeling.imageZoomPreference === 'cropped') {
				setLabelingImageZoomPreference('original')
			} else {
				setLabelingImageZoomPreference('cropped')
			}
		},
		[labeling.imageZoomPreference]
	)

	useHotkeys(
		'p',
		() => setPreloadEnabled(!preloadEnabled),
		[preloadEnabled]
	)

	useHotkeys(
		'shift+up',
		() => {
			setImageSettings({
				...imageSettings,
				brightness: Math.min(imageSettings.brightness + 0.25, 4),
			})
		},
		[imageSettings]
	)

	useHotkeys(
		'shift+down',
		() => {
			setImageSettings({
				...imageSettings,
				brightness: Math.max(imageSettings.brightness - 0.25, 0),
			})
		},
		[imageSettings]
	)

	useHotkeys(
		'left',
		() => {
			if (imgIndex > 0) {
				setImgIndex(imgIndex - 1)
			}
		},
		[imgIndex]
	)
	useHotkeys(
		'right',
		() => {
			if (imgIndex < richDocument.images.length - 1) {
				setImgIndex(imgIndex + 1)
			}
		},
		[richDocument, imgIndex]
	)

	useHotkeys(
		'shift+Enter',
		() => {
			try {
				const target = labeling.worklist[index - 1]
				setImg('')
				initImageIndex()
				setLabelingId(target.id)
				setImageSettings({
					contrast: 1,
					saturation: 1,
					brightness: 1,
				})
			} catch (err) {}
		},
		[index]
	)

	useHotkeys('up', () => {
		guideRef.current.scrollTop -= 80
	})
	useHotkeys('down', () => {
		guideRef.current.scrollTop += 80
	})

	useHotkeys('r', () => {
		setImageSettings({
			contrast: 1,
			saturation: 1,
			brightness: 1,
		})
	})

	useHotkeys('t', () => {
		setTimer(0)
		refTimer = 0
	})

	const handleClose = () => {
		props.setTableVisibility(false)
	}

	const onSaveComment = async (uid, comment, reset) => {
		const document = totalDataset.find((item) => item.id === uid)
		try{
			await fireStore
				.collection(labeling.currentDatabase)
				.doc(document.id)
				.set({
					lastModified: new Date(),
					comment: comment,
				}, {merge: true})
				reset({}, {keepValues:true})
		}
		catch(err){
			console.error(err)
		}
	}

	const onSave = async (uid, explicitLabels, modifiedLabel) => {
		const saveLabels = explicitLabels || labels
		const document = totalDataset.find((item) => item.id === uid)
    const priorHistory = document.history || {}
    const priorQueueHistory = priorHistory[labeling.currentDatabase] || {}
    const priorLabelHistory = priorQueueHistory[modifiedLabel] || {}
		try {
			await fireStore
				.collection(labeling.currentDatabase)
				.doc(document.id)
				.set({
          lastModified: new Date(),
					labels: {
						...document.labels,
						...saveLabels,
					},
					meta: {
						...document.meta,
						labeled_at: new Date().toISOString(),
						[`${modifiedLabel}_labeled_at`]: new Date().toISOString(),
					},
          history: {
            ...priorHistory,
            [labeling.currentDatabase]: {
              ...priorQueueHistory,
              [modifiedLabel]: {
                ...priorLabelHistory,
                user: auth.email,
                value: explicitLabels[modifiedLabel]
              }
            }
          }
				}, {merge: true})
			window.analytics &&
				window.analytics.track(
					'SET_CLASS_LABEL',
					{
						uuid: document.id,
						class: modifiedLabel,
						ellapsed: refTimer,
					},
					{}
				)
			// setTimer(0)
		} catch (err) {
			props.logoutUser('Security Token Expired.')
			console.log(err)
		}
	}

	useEffect(() => {
		let interval = null
		interval = setInterval(() => {
			setTimer((timer) => timer + 1)
			refTimer = timer
		}, 1000)
		return () => clearInterval(interval)
	}, [timer, imgLoading])

	useEffect(() => {
		if (labeling.open) {
			try {
				initImageIndex()
			} catch (err) {
				console.log(err)
			}
		}
	}, [labeling.open])

	const initImageIndex = () => {
		setImgIndex(0)
		setTimer(0)
	}

	const preloadImage = async (document, imgIndex, zoom) => {
		if (!preloadEnabled) return;

		const key = `${document.id}-${imgIndex}-${zoom}`;

		if (Cache[key]) {
			return Cache[key].url;
		} else {
			const callback = zoom === 'cropped' ? fetchLabelingImage : fetchOriginalImage
			const url = await callback(document.id, document.images[imgIndex])
			Cache[key] = { url: url }
			// load the image into the browser
			setTimeout(() => {
				const preloadedImg = new window.Image()
				preloadedImg.crossOrigin = true
				preloadedImg.src = url
				// store the image in var to keep in memory
				images.push(preloadedImg)
			}, 1000 + imgIndex * 300)
			return url
		}
	}

	useEffect(() => {
		if (richDocument && richDocument.images) {
			richDocument.images.map((_, i) => {
				// the first image is loaded either by next document, or initiall by Surface component
				if (i > 0) preloadImage(richDocument, i, labeling.imageZoomPreference)
			})
		}
	}, [richDocument, labeling, preloadEnabled])

	useEffect(() => {
		if (nextRichDocument?.images)
			nextRichDocument.images.map((_, i) => {
				// only load the first image in the next document
				if (i < 1) preloadImage(nextRichDocument, i, labeling.imageZoomPreference)
			})
	}, [nextRichDocument, labeling, preloadEnabled])

	const fetchImage = useCallback(
		_debounce(async () => {
			setImgLoading(true)
			if (labeling.imageZoomPreference === 'cropped') {
				const url = await fetchLabelingImage(richDocument.id, richDocument.images[imgIndex])
				Cache[`${richDocument.id}-${imgIndex}-${labeling.imageZoomPreference}`] = {
					url: url,
				}
				if (url.includes(richDocument.id)) {
					setImg(url)
				}
				setImgLoading(false)
			} else {
				const url = await fetchOriginalImage(richDocument.id, richDocument.images[imgIndex])
				Cache[`${richDocument.id}-${imgIndex}-${labeling.imageZoomPreference}`] = {
					url: url,
				}
				if (url.includes(richDocument.id)) {
					setImg(url)
				}
				setImgLoading(false)
			}
		}, 100),
		[richDocument, imgIndex, labeling.imageZoomPreference]
	)

	useEffect(async () => {
		if (labeling.open && richDocument) {
			if (!Cache[`${richDocument.id}-${imgIndex}-${labeling.imageZoomPreference}`]) {
				fetchImage()
			} else {
				if (img !== Cache[`${richDocument.id}-${imgIndex}-${labeling.imageZoomPreference}`].url) {
					setImg(Cache[`${richDocument.id}-${imgIndex}-${labeling.imageZoomPreference}`].url)
					setImgLoading(false)
				}
			}
		}
	}, [labeling.uid, imgIndex, labeling.imageZoomPreference])

	const comment = richDocument?.comment ?? ""

	const labeled_at = richDocument
		? props.labeling.interestedCharacteristics.length > 1
			? richDocument.meta.labeled_at
			: richDocument.meta[`${props.labeling.interestedCharacteristics[0]}_labeled_at`]
		: null

	const labeling_confidence = richDocument
		? props.labeling.interestedCharacteristics.length === 1
			? richDocument.meta.confidence
				? richDocument.meta.confidence[props.labeling.interestedCharacteristics[0]]
				: null
			: null
		: null

	const low_confidence_labels = richDocument?.low_confidence_labels ?? []

	const lablesGroups = Object.keys(richDocument?.labels || []).reduce(
		(acc, label, index) => {
			if (label.startsWith('_')) {
				acc.userLabels.push({
					key: index,
					name: label.replace(/_/g, ''),
					value: richDocument.labels[label],
				})
			} else {
				acc.dietaLabels.push({
					key: index,
					name: label,
					value: richDocument.labels[label],
				})
			}
			return acc
		},
		{
			userLabels: [],
			dietaLabels: [],
		}
	)
  
  let suggestions = (richDocument && suggestedLabels) ? 
    props.labeling.interestedCharacteristics.length === 1 ?
    (suggestedLabels[VerboseLabelsNameMap[props.labeling.interestedCharacteristics[0]]] ? suggestedLabels[VerboseLabelsNameMap[props.labeling.interestedCharacteristics[0]]][richDocument.id] : []) : []
    : []
    

  suggestions = (suggestions || []).filter((d) => { return (d !== 'N/A') }).map((i) => Number(i))

  let historicalLabeler = (richDocument && richDocument.history && richDocument.history[labeling.currentDatabase]) ?
    props.labeling.interestedCharacteristics.length === 1 ? 
      richDocument.history[labeling.currentDatabase][props.labeling.interestedCharacteristics[0]] ?
        richDocument.history[labeling.currentDatabase][props.labeling.interestedCharacteristics[0]].user
        : ''
      : ''
    : ''

	const toggleArrayItem = (arr, item) => {
		const res = arr.includes(item)
			? arr.filter(i => i !== item) // remove item
			: [ ...arr, item ]; // add item
	}

	const onToggleLowConfidence = async(uid, characteristic, value) => {
		let new_labels
		if(!value){
			new_labels = low_confidence_labels.includes(characteristic) ? low_confidence_labels : [...low_confidence_labels, characteristic]
		}
		else{
			new_labels = low_confidence_labels.filter(i => i !== characteristic)
		}
		
		const document = totalDataset.find((item) => item.id === uid)
		try{
			await fireStore
				.collection(labeling.currentDatabase)
				.doc(document.id)
				.set({
					lastModified: new Date(),
					low_confidence_labels: new_labels
				}, {merge: true})
		}
		catch(err){
			props.logoutUser("Security token expired")
			console.err(err)
		}
	}


	if (!richDocument) {
		return <></>
	} else {
		return (
			<div>
				<Dialog
					fullScreen={true}
					disableEscapeKeyDown
					open={labeling.open}
					aria-labelledby="responsive-dialog-title"
					className={classes.dialogContainer}
				>
					<Drawer
						className={classes.drawer}
						variant="permanent"
						classes={{
							paper: classes.drawerPaper,
						}}
						anchor="left"
					>
						<div ref={guideRef} className={classes.scrollGuideContainer}>
							<img
								className={classes.containImage}
								src={
									labeling.characteristics.find(
										(characteristic) => characteristic.name === labeling.visibleGuide
									).guides[labeling.guidePreference]
								}
							></img>
						</div>
					</Drawer>
					<DialogTitle id={'#top'}>
						<Grid container spacing={6} alignItems="center" justifyContent="center">
							<Grid item xs>
								Labeling Workbench ({richDocument.id})
							</Grid>
              <Grid item>
              <Button
                  style={{
                    paddingVertical: 0,
                    maddingHorizontal: 10
                  }}
                  variant={'outlined'}
                  onClick={() => {
                    setGuideShortcutsModalVisibility(true)
                  }}
                >
                  Shortcuts
                </Button>
              </Grid>
			  <Grid item>
              </Grid>
							<Grid item>
								<Grid container alignItems="center" justifyContent="center">
									<Typography
										style={{
											margin: 'auto',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										Label Timer: {timer}
									</Typography>
									<Tooltip title="t (reset)" aria-label="t">
										<IconButton
											onClick={() => {
												setTimer(0)
											}}
										>
											<RotateLeft />
										</IconButton>
									</Tooltip>
								</Grid>
							</Grid>
							<Grid item>
								<Grid container>
									<Typography
										style={{
											margin: 'auto',
										}}
									>
										{index + 1} / {labeling.worklist.length}
									</Typography>

									<Tooltip title="shift+enter" aria-label="shift+enter">
										<span>
											<IconButton
												onClick={() => {
													const target = labeling.worklist[index - 1]
													setImg('')
													initImageIndex()
													setLabelingId(target.id)
													setImageSettings({
														contrast: 1,
														saturation: 1,
														brightness: 1,
													})
												}}
												disabled={index === 0 || labeling.worklist[index - 1] === undefined}
											>
												<ArrowBack />
											</IconButton>
										</span>
									</Tooltip>
									<Tooltip title="enter" aria-label="enter">
										<span>
											<IconButton
												onClick={() => {
													const target = labeling.worklist[index + 1]
													setImg('')
													initImageIndex()
													setLabelingId(target.id)
													setImageSettings({
														contrast: 1,
														saturation: 1,
														brightness: 1,
													})
												}}
												disabled={labeling.worklist[index + 1] === undefined}
											>
												<ArrowForward />
											</IconButton>
										</span>
									</Tooltip>
								</Grid>
							</Grid>
						</Grid>
					</DialogTitle>
					<DialogContent dividers={true}>
						<Grid container spacing={4}>
							<Grid item xs={12}>
								<Grid container alignItems="flex-start">
									<Grid item xs={9}>
										<Grid container>
											<Grid item xs={10}>
												<Grid
													container
													spacing={2}
													alignItems="stretch"
													direction={'row'}
													justifyContent="space-between"
												>
													<Grid item xs={4}>
														<Field
															value={imageSettings.contrast}
															onChange={(contrast) =>
																setImageSettings({ ...imageSettings, contrast })
															}
															label="contrast"
														/>
													</Grid>
													<Grid item xs={4}>
														<Field
															value={imageSettings.saturation}
															onChange={(saturation) =>
																setImageSettings({ ...imageSettings, saturation })
															}
															label="saturation"
														/>
													</Grid>
													<Grid item xs={4}>
														<Field
															value={imageSettings.brightness}
															onChange={(brightness) =>
																setImageSettings({ ...imageSettings, brightness })
															}
															label="brightness"
															hotkey="shift+up/down"
														/>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={2}>
												<Grid
													container
													alignItems={'center'}
													direction="row"
													justifyContent={'center'}
												>
													<Grid item>
														<Button
															variant="contained"
															key={'reset'}
															onClick={() => {
																setImageSettings({
																	contrast: 1,
																	saturation: 1,
																	brightness: 1,
																})
															}}
														>
															Reset (r)
														</Button>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										{/* {!imgLoading ? ( */}
											<Surface
												version="webgl"
												width={labeling.imageZoomPreference === 'original' ? 800 : 800}
												height={labeling.imageZoomPreference === 'original' ? 1000 : 700}
												style={{
													width: '100%',
													height: '100%',
													padding: 0,
												}}
												onLoadError={(e) => {
													fetchImage()
												}}
											>
												<Saturate
													version="webgl"
													contrast={imageSettings.contrast}
													saturation={imageSettings.saturation}
													brightness={imageSettings.brightness}
												>
													{img}
												</Saturate>
											</Surface>
										{/* ) : (
											<Grid container justifyContent="center" className={classes.container}>
												<CircularProgress />
											</Grid>
										)} */}
									</Grid>
									<Grid item xs={3}>
                    <div>
										{/* <Sticky top={'#top'} enabled={true}> */}
											<Grid container direction="column" style={{position: 'relative', zIndex: 100}}>
												<Grid>
													{props.labeling.interestedCharacteristics.length > 1 && (
														<Alert
															variant="outlined"
															severity="warning"
															style={{
																marginBottom: 20,
															}}
														>
															Only select one classification at a time! Go back and select
															one class at the top of the list view.
														</Alert>
													)}

                          <Paper className={classes.paper}>
														<LabelUniformityMarker
															uid={richDocument.id}
															onChange={setLabels}
															onSave={onSave}
															key={richDocument.id}
															data={lablesGroups.dietaLabels}
															interestedCharacteristics={interestedCharacteristics}
														/>
													</Paper>
													<Paper className={classes.paper}>
														<LowConfidenceMarker
															onToggle={onToggleLowConfidence}
															uid={richDocument.id}
															data={low_confidence_labels}
															characteristic={interestedCharacteristics[0].name}
															selected={low_confidence_labels.includes(interestedCharacteristics[0].name)}
														/>
													</Paper>
													{/* <Paper className={classes.paper}>
														<AdequateMarker
															uid={richDocument.id}
															onChange={setLabels}
															onSave={onSave}
															key={richDocument.id}
															data={lablesGroups.dietaLabels}
															interestedCharacteristics={interestedCharacteristics}
														/>
													</Paper> */}
													<Paper className={classes.paper}>
														<AppBar position="static" color="default" elevation={0}>
															<Toolbar>
																<Typography
																	variant="h5"
																	color="textSecondary"
																	component="h5"
																>
																	Your Labels
																</Typography>
															</Toolbar>
														</AppBar>

														{props.labeling.interestedCharacteristics.length > 1 ? (
															<LabelChipListEditable
																uid={richDocument.id}
                                suggestions={suggestions}
                                isAdmin={isAdmin}
																onChange={setLabels}
																setGuide={(name) => {
																	if (labeling.visibleGuide !== name) {
																		props.setVisibleLabelingGuide(name)
																	}
																}}
																onSave={onSave}
																key={richDocument.id}
																data={lablesGroups.dietaLabels}
																interestedCharacteristics={interestedCharacteristics}
															/>
														) : (
															<LabelEfficientPrompt
																uid={richDocument.id}
                                suggestions={suggestions}
                                isAdmin={isAdmin}
																onChange={setLabels}
																setGuide={(name) => {
																	if (labeling.visibleGuide !== name) {
																		props.setVisibleLabelingGuide(name)
																	}
																}}
																onSave={onSave}
																key={richDocument.id}
																data={lablesGroups.dietaLabels}
																interestedCharacteristics={interestedCharacteristics}
															/>
														)}
													</Paper>

                          {(labeling.profile.role === 'QA' || labeling.profile.role === 'Admin') && richDocument.history && labeling.currentDatabase.startsWith('queue') && labeling.currentDatabase.endsWith('QA')? (
                            <Paper className={classes.paper}>
                              <AppBar position="static" color="default" elevation={0}>
                                <Toolbar>
                                  <Typography
                                    variant="h5"
                                    color="textSecondary"
                                    component="h5"
                                  >
                                    Labeler Resonses
                                  </Typography>
                                </Toolbar>
                              </AppBar>
                              <HeatmapLabels key={richDocument.id} document={richDocument} />
                            </Paper>
                          ) : (<></>)}

													<Paper className={classes.paper}>
														<AppBar position="static" color="default" elevation={0}>
															<Toolbar>
																<Typography
																	variant="h5"
																	color="textSecondary"
																	component="h5"
																>
																	Completed Labels
																</Typography>
															</Toolbar>
														</AppBar>
														<LabelChipList data={lablesGroups.dietaLabels} />
													</Paper>

													<BMComment
														onSave={onSaveComment}
														uid={richDocument.id}
														data={comment}
													/>
                          
													{ /*
                          {!props.auth.email.endsWith('@cshs.org') ? (
                            <Paper component={'div'} style={{
                              float: 'right',
                              justifyContent: "center",
                              alignItems: "center"
                            }} elevation={0}>
                              <Button variant="contained"
                              style={{
                                backgroundColor: "red"
                              }}
                              onClick={async () => {
                                const answer = window.confirm(`Remove this bowel movement from the worklist? This should only be used if there is a problem with the pictures (no pictures, not a bowel movement, can't see clearly)`)
                                if (answer) {
                                  await fireStore.collection('queue-stool-training-data-manual-review-QA').doc(richDocument.id).set(richDocument, {merge:true});
                                  await fireStore.collection(labeling.currentDatabase).doc(richDocument.id).delete();
                                  try { await fireStore.collection("queue-stool-training-data-CLFA-A").doc(richDocument.id).delete(); } catch (err) {}
                                  try { await fireStore.collection("queue-stool-training-data-CLFA-B").doc(richDocument.id).delete(); } catch (err) {}
                                  const target = labeling.worklist[index + 1]
                                  if (target) {
                                    setImg('')
                                    initImageIndex()
                                    setLabelingId(target.id)
                                    setImageSettings({
                                      contrast: 1,
                                      saturation: 1,
                                      brightness: 1,
                                    })
                                  }
                                } else {
                                  
                                }
                              }}>Invalid Bowel Movement Entry</Button>
                            </Paper>
                          ) : (<></>)}
													*/ }

												</Grid>
											</Grid>
										{/* </Sticky> */}
                    </div>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions id={'footer'}>
						<PermMedia
							fontSize="large"
							style={{
								padding: 5,
							}}
							color="primary"
						/>
						<Tooltip title="left arrow key (prev)" aria-label="left arrow key">
							<ArrowBack
								style={{
									padding: 4,
									alignItems: 'inherit',
									textTransform: 'inherit',
									justifyContent: 'inherit',
								}}
							></ArrowBack>
						</Tooltip>

						<Grid item>
							{richDocument.images.map((img, i) => {
								return (
									<Button
										key={`${img}-${i}-${richDocument.id}-${labeling.imageZoomPreference}`}
										style={{
											padding: 5,
											marginRight: i === richDocument.images.length - 1 ? 0 : 15,
										}}
										variant={imgIndex === i ? 'contained' : 'outlined'}
										color="primary"
										onClick={() => {
											setImgIndex(i)
										}}
									>
										{i + 1}
									</Button>
								)
							})}
						</Grid>

						<Tooltip title="right arrow (next)" aria-label="right arrow">
							<ArrowForward
								style={{
									padding: 4,
									alignItems: 'inherit',
									textTransform: 'inherit',
									justifyContent: 'inherit',
								}}
							></ArrowForward>
						</Tooltip>

						<Grid item>
							<Grid container alignItems="center" justify="center">
								<Tooltip title="toggle (o)" aria-label="toggle zoom">
									<Switch
										checked={labeling.imageZoomPreference === 'cropped' ? true : false}
										onChange={(event) => {
											setLabelingImageZoomPreference(
												event.target.checked ? 'cropped' : 'original'
											)
										}}
										name="imageZoomPreference"
										color="primary"
									/>
								</Tooltip>
								<Typography
									variant="subtitle2"
									style={{
										padding: 8,
										display: 'inherit',
										alignItems: 'inherit',
										textTransform: 'inherit',
										justifyContent: 'inherit',
									}}
								>
									Zoom ({labeling.imageZoomPreference === 'cropped' ? 'ON' : 'OFF'})
								</Typography>
							</Grid>
						</Grid>

						<Grid item>
							<Grid container alignItems="center" justify="center">
								<Tooltip title="toggle (p)" aria-label="toggle preloading">
									<Switch
										checked={preloadEnabled}
										onChange={() => setPreloadEnabled(!preloadEnabled)}
										name="preloadEnabled"
										color="primary"
									/>
								</Tooltip>
								<Typography
									variant="subtitle2"
									style={{
										padding: 8,
										display: 'inherit',
										alignItems: 'inherit',
										textTransform: 'inherit',
										justifyContent: 'inherit',
										whiteSpace: 'nowrap'
									}}
								>
									Preloading ({preloadEnabled ? 'ON' : 'OFF'})
								</Typography>
							</Grid>
						</Grid>

						<Grid item xs></Grid>

						{labeled_at ? (
							<Grid item>
								<Grid container alignItems="center" justify="center">
									<Grid item style={{}}>
										<Typography
											variant="subtitle2"
											style={{
												padding: 8,
												display: 'inherit',
												alignItems: 'inherit',
												textTransform: 'inherit',
												justifyContent: 'inherit',
											}}
										>
											Last edited: {moment(labeled_at).format('MM-DD h:mm a')}
                      {historicalLabeler ? ` ${historicalLabeler}` : ''}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						) : (
							<></>
						)}

						{labeling_confidence && isAdmin ? (
							<Grid item>
								<Grid container alignItems="center" justify="center">
									<Grid item style={{}}>
										<Typography
											variant="subtitle2"
											style={{
												padding: 8,
												display: 'inherit',
												alignItems: 'inherit',
												textTransform: 'inherit',
												justifyContent: 'inherit',
											}}
										>
											Confidence: {labeling_confidence}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						) : (
							<></>
						)}

						<Grid item>
							<Button onClick={() => setGuideModalVisibility(true)} variant="contained">
								Guides
							</Button>
						</Grid>
						<Grid item>
							<Button onClick={handleClose} variant="contained">
								Close
							</Button>
						</Grid>
					</DialogActions>
				</Dialog>
				<Modal
					className={classes.modal}
					open={guideModalOpen}
					onClose={() => {
						setGuideModalVisibility(false)
					}}
					aria-labelledby="labeling guides"
					aria-describedby="links for labeling guides"
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
				>
					<Fade in={guideModalOpen}>
						<Paper
							style={{
								display: 'flex',
								flexDirection: 'column',
								flexWrap: 'wrap',
								padding: 10,
							}}
						>
							<Typography variant="h5" color="textSecondary" component="h5">
								Settings
							</Typography>
							<Typography variant="subtitle2" color="textSecondary" component="h5">
								Set your preference for the left active guide
							</Typography>
							<Typography>
								<Grid component="label" container alignItems="center" spacing={1}>
									<Grid item>Larger</Grid>
									<Grid item>
										<Switch
											checked={labeling.guidePreference === 'tall' ? true : false}
											onChange={(event) => {
												setLabelingGuidePreference(event.target.checked ? 'tall' : 'large')
											}}
											name="guidePreference"
											color="primary"
										/>
									</Grid>
									<Grid item>Taller</Grid>
								</Grid>
							</Typography>
							<Divider />
							<Typography variant="h5" color="textSecondary" component="h5">
								Downloads
							</Typography>
							<Typography variant="subtitle2" color="textSecondary" component="h5">
								Use these to open up in a separate window <br />
								or download to your computer a copy of the guides
							</Typography>
							<Grid
								container
								spacing={2}
								alignItems="flex-start"
								direction="column"
								style={{ marginTop: 5 }}
							>
								{labeling.characteristics.map((characteristic) => {
									return (
										<Grid item key={characteristic.name}>
											<Typography className={classes.capitalize}>
												{characteristic.name.replace('_', ' ')}
											</Typography>
											<Grid container spacing={2}>
												{Object.keys(characteristic.guides).map((guideKey) => {
													return (
														<Grid item key={guideKey}>
															<Button
																className={classNames([
																	classes.button,
																	classes.capitalize,
																])}
																variant="outlined"
																color="inherit"
																size="small"
																onClick={() => {
																	window.open(characteristic.guides[guideKey])
																}}
															>
																{guideKey}
															</Button>
														</Grid>
													)
												})}
											</Grid>
										</Grid>
									)
								})}
							</Grid>
						</Paper>
					</Fade>
				</Modal>
        <Modal
          className={classes.containedModal}
          open={shortcutsModalOpen}
          onClose={() => {
            setGuideShortcutsModalVisibility(false)
          }}
          aria-labelledby="labeling shortcuts"
          aria-describedby="view shortcuts"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={shortcutsModalOpen}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <img
                  className={classes.containImage}
                  src={
                    'https://dieta-public-resources.s3-us-west-2.amazonaws.com/labeling_shortcuts.png'
                  }
                ></img>{' '}
              </Grid>
            </Grid>
          </Fade>
        </Modal>
			</div>
		)
	}
}

LabelingDialogComponent.propTypes = {
  auth: PropTypes.shape({
    email: PropTypes.string.isRequired
  }),
	labeling: PropTypes.shape({
		uid: PropTypes.string.isRequired,
		open: PropTypes.bool.isRequired,
    currentDatabase: PropTypes.string,
		visibleGuide: PropTypes.string.isRequired,
		orderMap: PropTypes.shape({}),
		characteristics: PropTypes.array,
		interestedCharacteristics: PropTypes.array,
    profile: PropTypes.shape({
      role: PropTypes.string
    }),
	}),
	firestore: PropTypes.shape({
		ordered: PropTypes.shape({}),
	}),
	logoutUser: PropTypes.func.isRequired,
	fetchLabelingImage: PropTypes.func.isRequired,
	fetchOriginalImage: PropTypes.func.isRequired,
	setLabelingId: PropTypes.func.isRequired,
	setTableVisibility: PropTypes.func.isRequired,
	setInterestedCharacteristics: PropTypes.func.isRequired,
	setLabelingGuidePreference: PropTypes.func.isRequired,
	setLabelingImageZoomPreference: PropTypes.func.isRequired,
}

const mapStateToProps = ({ auth, labeling, firestore }) => {
	return {
    auth,
		labeling,
		firestore,
	}
}

const LabelingDialog = connect(mapStateToProps, actions)(LabelingDialogComponent)
export { LabelingDialog }
